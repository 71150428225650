import React from 'react';
import { MdCheck } from 'react-icons/md';
import { useProduct } from '../../../hooks/product';
import formatPrice from '../../../utils/formatPrice';

import { Container } from './styles';

const Summary: React.FC = ({ children }) => {
  const { product } = useProduct();

  // Cronograma de estudos digital Educacione

  return (
    <Container>
      {children}

      <aside>
        <div>
          <h2>Sua compra</h2>

          <div>
            <span>{product.name}</span>
            <small>{product.duration_in_months} meses de acesso</small>
          </div>

          <ul>
            <li>
              <MdCheck /> Acesso total a todas as ferramentas
            </li>
            <li>
              <MdCheck /> Cronograma de estudos mais completo e tecnológico do
              mercado
            </li>
            <li>
              <MdCheck /> Quadro de Metas baseado no SISU
            </li>
            <li>
              <MdCheck /> SimuEDU: O maior banco de simulados no padrão ENEM do
              Brasil
            </li>
            <li>
              <MdCheck /> AtualizaEDU: Atualidades e geopolítica para o ENEM
            </li>
            <li>
              <MdCheck /> EDUflix: Repertórios para todos os temas de Redação
            </li>
            <li>
              <MdCheck /> Cronograma semanal
            </li>
            <li>
              <MdCheck /> Cronograma de Planejamento diário
            </li>
            <li>
              <MdCheck /> Conteúdos e materiais exclusivos
            </li>
            <li>
              <MdCheck /> Todos os meus modelos de redação
            </li>
            <li>
              <MdCheck /> Mais de 100 propostas de tema de Redação
            </li>
            <li>
              <MdCheck /> Cronograma de produção textual
            </li>
            <li>
              <MdCheck /> Descontos exclusivos no meu curso de Redação
            </li>
            <li>
              <MdCheck /> EDUcards: Nosso sistema exclusivo de Flashcards
            </li>
          </ul>

          {product.discount > 0 && (
            <>
              <footer style={{ marginBottom: '4px' }}>
                <span>Subtotal</span>
                <div>
                  <span
                    style={{
                      fontSize: '12px',
                      textDecoration: 'line-through',
                      color: 'red',
                    }}
                  >
                    {formatPrice(product.price)}
                  </span>
                </div>
              </footer>

              <footer style={{ marginBottom: '20px' }}>
                <span>Desconto</span>
                <div>
                  <span style={{ fontSize: '12px' }}>
                    {formatPrice(product.discount)}
                  </span>
                </div>
              </footer>
            </>
          )}

          <footer>
            <span>Total</span>

            <div>
              <small>1x</small>
              <span>{formatPrice(product.final_price)}</span>
            </div>
          </footer>
        </div>
      </aside>
    </Container>
  );
};

export default Summary;
